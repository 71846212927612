import { faDownload, faQrcode, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import VpnQrCode from './VpnQrCode';

import api from '../api';

import { WireguardPeer } from '../types';

const Vpn: React.FC = () => {
    // Implement your component logic here

    const [qrCodeData, setQrCodeData] = useState<string>(".");
    const [peers, setPeers] = useState<WireguardPeer[]>([]);
    
    const induceDownload = (data: string, filename: string, contentType: string) => {
        const a = document.createElement("a");
        const file = new Blob([data], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
    }

    // Function that accepts an int and prints it to console
    const getQrCode = (id: number, routed: boolean) => {
        api.get('wg/peer/' + id + '/raw').then(
            response => {
                setQrCodeData(response.data);
            }
        ).catch(
            error => {
                console.error(error);
            }
        )
    };

    // Retrieve the list of peers from database
    useEffect(() => {
        api.get('wg/peer').then(
            response => {
                console.log(response.data);
                setPeers(response.data);
            }
        ).catch(
            error => {
                console.error(error);
            }
        )
    }, []); // Remove 'peers' from the dependency array

    return (
        <div>
            
            <h1>WireGuard VPN Access</h1>
            
            <hr />

            <div>
                <Container className="justify-content-md-center">
                    <Card className="bg-dark text-white" style={{  width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Get Access</Card.Title>
                            <Card.Text>
                                Click to get access to the VPN.
                            </Card.Text>
                            <Button variant="primary">Create Profile</Button>
                        </Card.Body>
                    </Card>
                </Container>
                <hr />
                <h2>Current Profiles</h2>
                <Container>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th rowSpan={2}>Machine</th>
                                <th rowSpan={2}>VPN IP Address</th>
                                <th colSpan={2}>Get Profile</th>
                                <th rowSpan={2}>Revoke</th>
                            </tr>
                            <tr>
                                <th>LAN Only</th>
                                <th>Private Internet</th>
                            </tr>
                        </thead>
                        <tbody>
                            {peers.map((peer) => (
                                <tr key={peer.id}>
                                    <td>{peer.name}</td>
                                    <td><code>{peer.ipaddr}</code></td>
                                    <td>
                                        <Button variant="primary" onClick={() => induceDownload("Hello world",peer.name + ".conf","text/plain")}>
                                            <FontAwesomeIcon icon={faDownload} />&nbsp;Download
                                        </Button>&nbsp;
                                        <Button variant="primary" onClick={() => getQrCode(peer.id, false)}>
                                            <FontAwesomeIcon icon={faQrcode} />&nbsp;QR Code
                                        </Button>
                                    </td>
                                    <td>
                                        <Button variant="success" onClick={() => induceDownload("Hello world",peer.name + ".routed.conf","text/plain")}>
                                            <FontAwesomeIcon icon={faDownload} />&nbsp;Download
                                        </Button>&nbsp;
                                        <Button variant="success" onClick={() => getQrCode(peer.id, true)}>
                                            <FontAwesomeIcon icon={faQrcode} />&nbsp;QR Code
                                        </Button>
                                    </td>
                                    <td>
                                        <Button variant="danger">
                                            <FontAwesomeIcon icon={faTrash} />&nbsp;Revoke
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                            
                    </Table>
                </Container>

                <VpnQrCode data={qrCodeData} />
            </div>
        </div>
    );
};

export default Vpn;