import QRCode from "react-qr-code";

type VpnQrCodeProps = {
    data: string;
};

const VpnQrCode: React.FC<VpnQrCodeProps> = ({ data }) => {
    // Implement your component logic here
    return (
        <div>
            <p>Oh look, it's a QR code!</p>
            <span
                style={{
                    background: 'white',
                    padding: '22px',
                    boxSizing: 'border-box',
                    display: 'inline-block'
                }}
            >
                <QRCode size={512} value={data} />
            </span>
        </div>
    );
};

export default VpnQrCode;