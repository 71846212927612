import { Container, Nav, Navbar } from "react-bootstrap";

function NavbarTop() {
    return (
        <Navbar collapseOnSelect fixed="top" bg="dark" data-bs-theme="dark" expand="lg">
            <Container>
                <Navbar.Brand href="#home">mNet4</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/leases">DHCP Leases</Nav.Link>
                        <Nav.Link href="/vpn">VPN Access</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
           </Container>
        </Navbar>
    )
}

export default NavbarTop;
