import React, { useEffect, useState } from 'react';
import { DhcpLease } from '../types';
import api from '../api';

import { format } from 'timeago.js';

import './Leases.scss';
import { Button, Container, Table } from 'react-bootstrap';

const Leases: React.FC = () => {
    // Implement your component logic here

    const [leaseData, setLeaseData] = useState<DhcpLease[]>([]);
    const [isRefreshing, setIsRefreshing] = useState(true);


    useEffect(() => {
        let interval: NodeJS.Timeout;
    
        if (isRefreshing) {
            interval = setInterval(() => {
                api.get("dhcp/lease").then((response) => {
                    setLeaseData(response.data);
                }).catch(
                    error => {
                        console.error(error);
                    }
                )
            }, 10000); // 10000 milliseconds = 10 seconds
        }
    
        // Clear interval on component unmount or when isRefreshing changes
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isRefreshing]); // Depend on isRefreshing

    function formatDate(timestamp: string): string {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        const second = String(date.getSeconds()).padStart(2, '0');

        return `${day} ${getMonthName(month)} ${year} ${hour}:${minute}:${second}`;
    }

    function getMonthName(month: number): string {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        return months[month - 1];
    }

    // ...

    useEffect(() => {
        api.get("dhcp/lease").then((response) => {
            setLeaseData(response.data);
        }).catch(
            error => {
                console.error(error);
            }
        )
    }, []);
    
    return (
        <div>
            <h1>DHCP Leases</h1>
            
            <hr />

            <Container>
                <Button variant="secondary" onClick={() => setIsRefreshing(!isRefreshing)}>
                    {isRefreshing ? 'Stop Refreshing' : 'Start Refreshing'}
                </Button>
            </Container>
            
            <Container>
                <Table variant="dark" striped>
                    <thead>
                        <tr>
                            <th>Lease Time</th>
                            <th>Hostname</th>
                            <th>MAC Address</th>
                            <th>IP address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaseData.map((lease, index) => (
                            <tr key={index}>
                                <td>{formatDate(lease.timestamp)} <span className="text-secondary smaller">{format(lease.timestamp)}</span></td>
                                <td>{lease.hostname}</td>
                                <td><code>{lease.mac}</code></td>
                                <td><strong>{lease.ip}</strong></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    );
};

export default Leases;