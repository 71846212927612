import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Application components
import NavbarTop from './components/NavbarTop';
import NavbarBottom from './components/NavbarBottom';
import Leases from './components/Leases';
import Vpn from './components/Vpn';

function App() {
  return (
    <div className="App">

      <BrowserRouter>

        <NavbarTop />

          <Routes>
            
            <Route path="/" element={<p>Oh look, it's a React application!</p>} />
            <Route path="/leases" element={<Leases />} />
            <Route path="/vpn" element={<Vpn />} />

          </Routes>
        
        <NavbarBottom />

      </BrowserRouter>
    </div>
  );
}

export default App;
